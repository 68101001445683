// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about .content {
  background-color: #f6f9ff;
  padding: 40px;
}

.about h3 {
  font-size: 14px;
  font-weight: 700;
  color: #4154f1;
  text-transform: uppercase;
}

.about h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
}

.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about .btn-read-more {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.about .btn-read-more span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .btn-read-more:hover i {
  transform: translateX(5px);
}`, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,+CAA+C;AACjD;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".about .content {\n  background-color: #f6f9ff;\n  padding: 40px;\n}\n\n.about h3 {\n  font-size: 14px;\n  font-weight: 700;\n  color: #4154f1;\n  text-transform: uppercase;\n}\n\n.about h2 {\n  font-size: 24px;\n  font-weight: 700;\n  color: #012970;\n}\n\n.about p {\n  margin: 15px 0 30px 0;\n  line-height: 24px;\n}\n\n.about .btn-read-more {\n  line-height: 0;\n  padding: 15px 40px;\n  border-radius: 4px;\n  transition: 0.5s;\n  color: #fff;\n  background: #4154f1;\n  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);\n}\n\n.about .btn-read-more span {\n  font-family: \"Nunito\", sans-serif;\n  font-weight: 600;\n  font-size: 16px;\n  letter-spacing: 1px;\n}\n\n.about .btn-read-more i {\n  margin-left: 5px;\n  font-size: 18px;\n  transition: 0.3s;\n}\n\n.about .btn-read-more:hover i {\n  transform: translateX(5px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
